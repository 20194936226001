var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar-nav',{staticClass:"nav"},[_vm._l((_vm.bookmarks),function(bookmark,index){return _c('b-nav-item',{key:index,attrs:{"id":("bookmark-" + index),"to":bookmark.route}},[_c('feather-icon',{attrs:{"icon":bookmark.icon,"size":"21"}}),_c('b-tooltip',{attrs:{"triggers":"hover","target":("bookmark-" + index),"title":bookmark.title,"delay":{ show: 1000, hide: 50 }}})],1)}),_c('b-nav-item-dropdown',{attrs:{"link-classes":"bookmark-star","lazy":""},on:{"hidden":_vm.resetsearchQuery}},[_c('feather-icon',{staticClass:"text-warning",attrs:{"slot":"button-content","icon":"StarIcon","size":"21"},slot:"button-content"}),_c('li',{staticStyle:{"min-width":"300px"}},[_c('div',{staticClass:"p-1"},[_c('b-form-input',{attrs:{"id":"boomark-search-input","placeholder":"Explore Vuexy...","autofocus":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('vue-perfect-scrollbar',{staticClass:"\n          search-list search-list-bookmark\n          scroll-area\n        ",class:{
          show:
            _vm.filteredData.pages &&
            _vm.filteredData.pages.length,
        },attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"}},[_vm._l((_vm.filteredData.pages || _vm.bookmarks),function(suggestion,index){return _c('b-dropdown-item',{key:index,staticClass:"\n            suggestion-group-suggestion\n            cursor-pointer\n          ",attrs:{"link-class":"d-flex align-items-center","to":suggestion.route},on:{"mouseenter":function($event){_vm.currentSelected = index}}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":suggestion.icon,"size":"18"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(suggestion.title))]),_c('feather-icon',{staticClass:"ml-auto",class:{
              'text-warning':
                suggestion.isBookmarked,
            },attrs:{"icon":"StarIcon","size":"16"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleBookmarked(suggestion)}}})],1)}),_c('b-dropdown-item',{directives:[{name:"show",rawName:"v-show",value:(
            !(
              _vm.filteredData.pages &&
              _vm.filteredData.pages.length
            ) && _vm.searchQuery
          ),expression:"\n            !(\n              filteredData.pages &&\n              filteredData.pages.length\n            ) && searchQuery\n          "}],attrs:{"disabled":""}},[_vm._v(" No Results Found. ")])],2)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }