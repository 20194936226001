<template>
  <span class="float-md-right">Version {{ version }} </span>
</template>

<script>
import app from '@/../package.json'

export default {
  data() {
    return {
      version: app.version,
    }
  },
}
</script>

<style></style>
