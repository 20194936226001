<template>
  <div>
    <div v-if="minimal && visitors > 1 && $socket.connected">
      <b-badge
        id="visitors-count"
        class="cursor-pointer"
        pill
        variant="light-primary"
      >
        {{ visitors }}
      </b-badge>
      <b-tooltip
        triggers="hover"
        target="visitors-count"
        title="Current Active Visitors"
        :delay="{ show: 50, hide: 1000 }"
      />
    </div>

    <div
      v-if="!minimal && visitors > 1 && $socket.connected"
      class="text-center mt-2"
    >
      <b-badge id="visitors-count" pill variant="light-primary">
        {{ visitors.toLocaleString() }} Active Visitor{{
          visitors === 1 ? '' : 's'
        }}
      </b-badge>
    </div>

    <div v-if="!$socket.connected" class="text-center">
      <b-badge
        id="visitors-count"
        pill
        :variant="`light-${$socket.connected ? 'success' : 'danger'}`"
      >
        <b-spinner v-if="!$socket.connected" small type="grow" />
        {{ $socket.connected ? 'Connected' : 'Trying to connect to server' }}
      </b-badge>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BSpinner, BBadge, BTooltip } from 'bootstrap-vue'

export default {
  name: 'visitors-badge',
  props: {
    minimal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BBadge,
    BSpinner,
    BTooltip,
  },
  computed: {
    visitors() {
      return this.$store.state.app.visitors
    },
  },
}
</script>

<style></style>
