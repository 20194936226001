<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <visitors-badge :minimal="true" />

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.given_name
              }}{{ user.middle_name ? ` ${user.middle_name} ` : ' '
              }}{{ user.family_name }}
            </p>
            <span class="user-status">{{ user.preferred_username }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="user.picture"
            :text="avatarText(`${user.given_name} ${user.family_name}`)"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'profile' }"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click.stop="logout"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { avatarText } from '@core/utils/filter'
import { initialAbility } from '@/libs/acl/config'

import VisitorsBadge from '@/components/stats/VisitorsBadge.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    VisitorsBadge,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user ?? {}
    },
    visitors() {
      return this.$store.state.app.visitors
    },
  },
  methods: {
    avatarText,
    showToast(title, text, icon, variant, timeout = 10000) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            text,
            icon: `${icon}Icon`,
            variant,
          },
        },
        {
          position: 'bottom-right',
        },
        {
          timeout,
          // eslint-disable-next-line comma-dangle
        },
      )
    },
    logout() {
      // Redirect to login page
      this.$store.commit('auth/LOGOUT')
      this.$store.commit('auth/REMOVE_BEARER')
      this.showToast(
        'Logging Out ...',
        undefined,
        'Shield',
        'warning',
        // eslint-disable-next-line comma-dangle
        5000,
      )

      // Reset ability
      this.$ability.update(initialAbility)

      this.$router.push({ name: 'login' })
    },
  },
}
</script>
