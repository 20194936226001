export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    resource: 'Auth',
    action: 'read',
  },
  {
    title: 'Jobs',
    route: 'jobs',
    icon: 'AwardIcon',
    resource: 'Job',
    action: 'read',
  },
  {
    title: 'My Applications',
    route: 'my-applications',
    icon: 'ListIcon',
    resource: 'UserApplication',
    action: 'manage',
  },
  {
    title: 'Calendar',
    route: 'calendar',
    icon: 'CalendarIcon',
    resource: 'Auth',
    action: 'read',
  },
  {
    title: 'Profile',
    route: 'profile',
    icon: 'UserIcon',
    resource: 'UserProfile',
    action: 'manage',
  },
  {
    title: 'Update Profile',
    route: 'update-profile',
    icon: 'EditIcon',
    resource: 'UserProfile',
    action: 'manage',
  },
]
