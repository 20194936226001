/* eslint-disable implicit-arrow-linebreak */
import jwt from '@/utils/jwt'
import store from '@/store'
import router from '@/router'

import keys from '@/utils/keys'

import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  store = store

  router = router

  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = {
      ...this.jwtConfig,
      ...jwtOverrideConfig,
    }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      // eslint-disable-next-line comma-dangle
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (
          // eslint-disable-next-line operator-linebreak
          response &&
          // eslint-disable-next-line operator-linebreak
          response.status === 401 &&
          response.config.url !== this.jwtConfig.loginEndpoint
        ) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken()
              .then(async r => {
                this.isAlreadyFetchingAccessToken = false

                localStorage.setItem('token', r.data.data)

                const jwtPayload = await jwt.verify(
                  keys.public_key(),
                  // eslint-disable-next-line comma-dangle
                  r.data.data,
                )

                if (jwtPayload) {
                  this.store.commit(
                    'auth/SET_BEARER',
                    // eslint-disable-next-line comma-dangle
                    jwtPayload.access_token,
                  )
                  this.store.commit(
                    'auth/UPDATE_JWT_TOKEN',
                    // eslint-disable-next-line comma-dangle
                    r.data.data,
                  )
                  this.store.commit(
                    'auth/UPDATE_AUTH_USER',
                    // eslint-disable-next-line comma-dangle
                    jwtPayload.user,
                  )
                  this.store.commit(
                    'auth/UPDATE_ACCESS_TOKEN',
                    // eslint-disable-next-line comma-dangle
                    {
                      access_token: jwtPayload.access_token,
                      refresh_token: jwtPayload.refresh_token,
                      // eslint-disable-next-line comma-dangle
                    },
                  )

                  this.onAccessTokenFetched(jwtPayload.access_token)
                } else {
                  this.loading = false
                  throw new Error(
                    // eslint-disable-next-line comma-dangle
                    'Invalid Token Received. Please try again later.',
                  )
                }
              })
              .catch(e => {
                this.store.commit('auth/LOGOUT')
                this.router.push({ name: 'login' })
                throw e
              })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
        // eslint-disable-next-line comma-dangle
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(
      callback =>
        // eslint-disable-next-line comma-dangle
        callback(accessToken),
      // eslint-disable-next-line function-paren-newline
    )
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return this.store.state.auth.access_token
  }

  getRefreshToken() {
    return this.store.state.auth.refresh_token
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refresh_token: this.getRefreshToken(),
    })
  }
}
